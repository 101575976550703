import { html } from 'lit'

const t = {
  play_now: 'Jugar ahora',
  news: 'Noticias',
  loading: 'Cargando...',
  skip: 'Saltar',
  next: 'Próximo',
  ok: 'Ok!',
  coach_step_1: html`¿Te diste cuenta de la nueva barra de navegación del <strong>ecosistema de Gamers Club?</strong>`,
  coach_step_2: html`¡Al hacer clic en el ícono <ie-icon class="nav-menu__button" pointer="true" type="menu"></ie-icon>, tenes acceso <strong>a todos los juegos y noticias</strong> del universo de GC en un solo lugar!`,
  coach_step_3: html`Elige un elemento del menú y <strong>¡disfruta de la experiencia!</strong>`
}

export default t
