import { html } from 'lit'

const t = {
  play_now: 'Jogar agora',
  news: 'Notícias',
  loading: 'Carregando...',
  skip: 'Pular',
  next: 'Próximo',
  ok: 'Entendi!',
  coach_step_1: html`Você já viu a nova barra de navegação do <strong>ecossistema Gamers Club?</strong>`,
  coach_step_2: html`Clicando no ícone <ie-icon class="nav-menu__button" pointer="true" type="menu"></ie-icon> você tem acesso a <strong>todos os jogos e notícias</strong> do universo GC <strong>em um só lugar!</strong>`,
  coach_step_3: html`Escolha um dos itens da lista para conhecer.`
}

export default t
