import { getWindowLanguageTag, translate } from '@root/src/helpers'
import { languages } from '@root/src/types'
import { designTokens } from '@ui-kit/design-tokens'
import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

type CoachMarksPhase = number | false | undefined
const storage = window.localStorage
const STORAGE_KEY = 'gcie-coach-marks-phase'

@customElement('coach-marks')
export class CoachMarks extends LitElement {
  static override styles = [designTokens, css`

    :host {
      color: var(--color-white);
      font-family: var(--font-family-primary);
      font-size: var(--font-size-18);
    }

    .coach-marks__shadow{
      display:none;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--color-dark-6);
      z-index: 1;
    }

    .coach-marks__steps{
      display:none;
      position: absolute;
      top: var(--space-64);
      left: calc(var(--space-64)*2);
      background-color: var(--color-gray-12);
      padding: var(--space-8);
      z-index: 10;
      max-width: calc(var(--space-base-unit) * 80);
      border: 1px solid var(--color-light-2);
      border-radius: var(--border-radius-medium);
      border-top-left-radius: 0;
      padding: var(--space-24);
    }
    .coach-marks__steps p {
      line-height: var(--line-height-32);
      margin: 0 0 var(--space-12) 0;
    }
    .coach-marks__steps p ie-icon{
      display: inline-flex;
      height: var(--font-size-16);
      width: var(--font-size-16);
      margin: 0 var(--space-4);
      margin-bottom: calc(var(--space-4)*-1);
    }


    .coach-marks__buttons-wrapper{
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      gap: var(--space-12);
    }

    .coach-marks__buttons-wrapper .ie-button-content{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: var(--space-32);
      gap: var(--space-12);
      font-size: var(--font-size-16);
      font-weight: var(--font-weight-bold);
    }

    

    .coach-marks--step-1 .coach-marks__steps--step1{
      display: block;
    }
    .coach-marks--step-2 .coach-marks__steps--step2{
      display: block;
    }
    .coach-marks--step-3 .coach-marks__steps--step3{
      top: calc(var(--space-64)*1.8);
      left: calc(var(--space-64)*5); 
      display: block;
    }
    .coach-marks--step-3 .coach-marks__buttons-wrapper{
      grid-template-columns: 2fr 2fr;
    }
    .coach-marks--active .coach-marks__shadow{
      display: block;
    }

    /** DESKTOP */
    @media (max-width:480px) {
      .coach-marks--step-1 .coach-marks__steps--step1,
      .coach-marks--step-2 .coach-marks__steps--step2,
      .coach-marks--step-3 .coach-marks__steps--step3{
        left:0;
        margin-right: var(--space-4);
      }
      .coach-marks--step-3 .coach-marks__steps--step3{
        bottom: 0;
        left:0;
        top: auto;
      }
    }

    /** TABLET */
    @media (min-width: 481px) and (max-width: 768px){
      .coach-marks--step-1 .coach-marks__steps--step1,
      .coach-marks--step-2 .coach-marks__steps--step2,
      .coach-marks--step-3 .coach-marks__steps--step3{
        left: var(--space-40);
      }

      .coach-marks--step-3 .coach-marks__steps--step3{
        left: calc(var(--space-64)*5);
        margin-left: var(--space-4);
      }
    }

  `]

  @property()
    defaultLanguage: languages = getWindowLanguageTag() as languages || languages.PT

  @state()
  private _coachMarksPhase?: CoachMarksPhase

  private _setCoachMarksPhase (phase: CoachMarksPhase) {
    this._coachMarksPhase = phase
  }

  private _nextCoachMarksPhase () {
    const phase = this._coachMarksPhase ? this._coachMarksPhase + 1 : 1
    this._coachMarksPhase = phase < 4 ? phase : false

    if (this._coachMarksPhase === 3) {
      this._openMenu()
    }
  }

  private _skipCoachMarks () {
    // salva no local
    this._coachMarksPhase = false
  }

  private _initCoachMarks (phase?: CoachMarksPhase) {
    // se não tiver salvo no local, inicia
    this._setCoachMarksPhase(phase ?? 1)
  }

  private _retrieveCoachMarksPhaseFromLocalStorage (): CoachMarksPhase {
    // pega do local
    const storedPhase = storage.getItem(STORAGE_KEY)
    if (storedPhase && storedPhase !== 'false') {
      return Number(storedPhase)
    }

    if (storedPhase === 'false') {
      return false
    }

    return undefined
  }

  private _openMenu () {
    this.dispatchEvent(new CustomEvent('globalnavopen', {
      bubbles: true,
      composed: true
    }))
  }

  override connectedCallback () {
    super.connectedCallback()
    const phase = this._retrieveCoachMarksPhaseFromLocalStorage()
    this._initCoachMarks(phase)
  }

  override updated (changedProperties: PropertyValues) {
    if (changedProperties.has('_coachMarksPhase')) {
      storage.setItem(STORAGE_KEY, String(this._coachMarksPhase))
    }
  }

  override render () {
    const coachMarksActiveClass = this._coachMarksPhase && 'coach-marks--active'
    const coachMarksStepClass = this._coachMarksPhase && `coach-marks--step-${this._coachMarksPhase ?? 0}`
    return html`
    <div class="coach-marks ${coachMarksActiveClass} ${coachMarksStepClass}">
      <div class="coach-marks__steps coach-marks__steps--step1">
        <p>${translate('coach_step_1', this.defaultLanguage)}</p>
        <!-- <p>Você já viu a nova barra de navegação do <strong>ecossistema Gamers Club?</strong></p> -->
        <div class="coach-marks__buttons-wrapper">
          <ie-button 
            type="horizontal" 
            fill="default"  
            @click=${this._skipCoachMarks} >
            <div class="ie-button-content">
              ${translate('skip', this.defaultLanguage)}
            </div>
          </ie-button>
          <ie-button 
            type="horizontal" 
            fill="primary" 
            @click=${this._nextCoachMarksPhase}>
            <div class="ie-button-content">
              <ie-icon type="arrowLeft"></ie-icon>
              <span>${translate('next', this.defaultLanguage)}</span>
            </div>
          </ie-button>
        </div>
      </div>
      <div class="coach-marks__steps coach-marks__steps--step2">
        <p>${translate('coach_step_2', this.defaultLanguage)}</p>
        <div class="coach-marks__buttons-wrapper">
          <ie-button 
            type="horizontal" 
            fill="default"   
            @click=${this._skipCoachMarks}>
            <div class="ie-button-content">
            ${translate('skip', this.defaultLanguage)}
            </div>
          </ie-button>
          <ie-button 
            type="horizontal" 
            fill="primary"   
            @click=${this._nextCoachMarksPhase}>
            <div class="ie-button-content">
              <ie-icon type="arrowLeft"></ie-icon>
              <span>${translate('next', this.defaultLanguage)}</span>
            </div>  
          </ie-button>
        </div>
      </div>
      <div class="coach-marks__steps coach-marks__steps--step3">
        <p>${translate('coach_step_3', this.defaultLanguage)}</p>
        <div class="coach-marks__buttons-wrapper">
          <ie-button 
            type="horizontal" 
            fill="primary" 
            @click=${this._skipCoachMarks}>
            <div class="ie-button-content">
              <ie-icon type="thumbsUp"></ie-icon>
              <span>${translate('ok', this.defaultLanguage)}</span>
            </div>
          </ie-button>
        </div>
      </div>
      <div class="coach-marks__shadow"></div>
    </div>`
  }
}
