import { languages, News } from '../types'

export const getNews = async (game: string, language: languages): Promise<News[]> => {
  const response = await fetch(`https://ecosystem-api.gamersclub.gg/news?game=${game}&lang=${language}`)
  return await response.json()
}

export const getNewsAlreadyRead = (): string[] => {
  return localStorage.getItem('newsAlreadyRead')?.split(',') ?? []
}

export const checkIfNewsIsAlreadyRead = (news: News): boolean => {
  return getNewsAlreadyRead().includes(news.link)
}

export const markNewsAsRead = (news: News): void => {
  const newsAlreadyRead = getNewsAlreadyRead()
  newsAlreadyRead.push(news.link)
  localStorage.setItem('newsAlreadyRead', newsAlreadyRead.join(','))
}

export enum DateMeasures {
  DAY = 'day',
  DAYS = 'days',
  HOUR = 'hour',
  HOURS = 'hours',
  MINUTE = 'minute',
  MINUTES = 'minutes',
  SECOND = 'second',
  SECONDS = 'seconds',
  MONTH = 'month',
  MONTHS = 'months',
  YEAR = 'year',
  YEARS = 'years'
}

export enum DateMeasuresPtBr {
  DAY = 'dia',
  DAYS = 'dias',
  HOUR = 'hora',
  HOURS = 'horas',
  MINUTE = 'minuto',
  MINUTES = 'minutos',
  SECOND = 'segundo',
  SECONDS = 'segundos',
  MONTH = 'mês',
  MONTHS = 'meses',
  YEAR = 'ano',
  YEARS = 'anos'
}

export const getDateElapsed = (date: string): string => {
  const dateNow = new Date()
  const dateNews = new Date(date)
  const diff = Math.abs(dateNow.getTime() - dateNews.getTime())
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
  const diffWeeks = Math.floor(diffDays / 7)
  const diffMonths = Math.floor(diffDays / 30)
  const diffYears = Math.floor(diffDays / 365)
  const diffMeasureType = diffYears > 0 ? 'year' : diffMonths > 0 ? 'month' : diffWeeks > 0 ? 'week' : 'day'
  const diffToShow = diffMonths > 0 ? diffMonths : diffWeeks > 0 ? diffWeeks : diffDays

  return `${diffToShow} ${DateMeasuresPtBr[diffMeasureType.toUpperCase() as keyof typeof DateMeasuresPtBr]} atrás`
}
