import { designTokens } from '@root/src/ui-kit/design-tokens'
import { css } from 'lit'

export const styles = [designTokens, css`
*{
  margin: 0;
  padding: 0;
}
:host{
  font-family: var(--font-family-primary);
}

a{
  text-decoration: none;
  color: var(--color-gray-1);
}

.drawer-menu__header{
  height: var(--space-64);
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.drawer-menu__header > * {
  display: inline-flex;
  justify-content: center;
  flex: 10;
}

.drawer-menu__header_close{
  flex:1;
  padding-right: var(--space-16); 
  cursor: pointer;
}

.drawer-menu__body > section, .drawer-menu__body > h2{
  padding: var(--space-16);
}
.drawer-menu__body > section.drawer-menu__body__module-feed{
  padding-left: 0;
  padding-right: 0;
  padding-bottom:0;
}
.drawer-menu__body > h2{
  padding-bottom: 0;
}
.drawer-menu__body > section{
  padding-bottom: var(--space-32);
}

.drawer-menu__body__module-play{
  display: grid;
  grid-gap: var(--space-8);
}

.drawer-menu__body__module-play--itens{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: var(--space-8);
}

.drawer-menu__body__module-play ie-button{
  width: 100%;
}


.drawer-menu__body__module-news{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: var(--space-8);

}


h2{
  text-transform: uppercase;
  font-size: var(--font-size-14);
  line-height: var(--line-height-large);
  color: var(--color-blue-gamersclub);
}

/***** FEED NEWSITEM *****/
.drawer-menu__body__module-feed{
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  color: var(--color-gray-1);
}
.drawer-menu__body__module-feed::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.news-item{
  padding-bottom: var(--space-16);
  border: 1px solid var(--color-light-2);
  margin-right: -1px; /* remove right border */
}
.news-item:last-of-type{
  padding-bottom: 0;
}
.news-item figure{
  margin: 0;
  position: relative;
}
.news-item figure img{
  min-width: calc(var(--space-4)*80);
  max-height: calc(var(--space-4)*58);
}

.news-item__data{
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient( rgba(0, 0, 0, 0.3) , rgba(0, 0, 0, 1));
}

.news-item__data h3{
  font-size: var(--font-size-16);
  margin: 0;
}

.news-item__data > *{
  padding: var(--space-16);
}

.news-item__data > *:last-child{
  margin-top: auto;
}

`]
