import { designTokens } from '@ui-kit/design-tokens'
import { css, html, LitElement } from 'lit'
import { customElement } from 'lit/decorators.js'

@customElement('ie-new-mark')
export class NewMark extends LitElement {
  static override styles = [designTokens, css`
  .new-mark{
      background-color: var(--color-red);
      width: var(--space-12);
      height: var(--space-12);
      border-radius: 50%;
      display: inline-block;
      animation: pulse 1s infinite;
    }
    @keyframes pulse {
      0% {
        transform: scale(0.55);
        box-shadow: 0 0 0 0 var(--color-red);
      }
      70% {
        transform: scale(0.7);
        box-shadow: 0 0 0 calc(var(--space-4)/2) var(--color-red);
      }
      100% {
        transform: scale(0.55);
        box-shadow: 0 0 0 0 var(--color-red);
      }
    }

  `]

  override render () {
    return html`
      <div class="new-mark">
        
      </div>
    `
  }
}
