import { GCMediaRelatedProducts, GCProductsAbbr, ProductsRelatedToGCMedia } from './../types'
import { GCBrands, GCMediaBrands } from '@root/src/types'

const brands = { ...GCMediaBrands, ...GCBrands }
const products = { ...GCProductsAbbr }
const defaultProduct = 'CSGO'

export const getBrandName = (brand: string): string => {
  return brands[brand as keyof typeof brands]
}

export const isGCMediaBrand = (brandAbbr: string): boolean => {
  return Object.keys(GCMediaBrands).includes(brandAbbr)
}

export const getBrandNameByProduct = (product: keyof typeof ProductsRelatedToGCMedia | string): string => {
  let brand = product
  if (Object.keys(ProductsRelatedToGCMedia).includes(product)) {
    brand = ProductsRelatedToGCMedia[product as keyof typeof ProductsRelatedToGCMedia]
  }
  const brandName = getBrandName(brand)
  return brandName || product
}

export const findProduct = (query: string): string => {
  if (Object.keys(GCMediaRelatedProducts).includes(query)) {
    return GCMediaRelatedProducts[query as keyof typeof GCMediaRelatedProducts]
  }

  if (Object.keys(products).includes(query)) {
    return products[query as keyof typeof products]
  }

  return defaultProduct
}
