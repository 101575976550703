import { css } from 'lit'

export const designTokens = css`

:host{
  /* Core units
   ================================================================= */
  --base-unit:        0.25rem;
  --base-half-unit:   calc(var(--base-unit) / 2);

  /* Typography
  ================================================================= */
  
  /* Fonts */

  --font-family-primary:    'Archivo';
  --font-family-secondary:  'Oswald';
  --font-family-gamersclub: 'Barlow','Oswald';
  --font-base-unit: var(--base-unit);
  
  /* Font sizes - 4x4 grid */
  --font-size-12: calc(var(--font-base-unit) * 3);
  --font-size-14: calc(var(--font-base-unit) * 3.5);
  --font-size-16: calc(var(--font-base-unit) * 4);
  --font-size-18: calc(var(--font-base-unit) * 4.5);
  --font-size-20: calc(var(--font-base-unit) * 5);
  --font-size-24: calc(var(--font-base-unit) * 6);
  --font-size-40: calc(var(--font-base-unit) * 10);
  --font-size-48: calc(var(--font-base-unit) * 12);
  --font-size-64: calc(var(--font-base-unit) * 16);
  --line-height-base-unit: 1;
  /* Line heights */
  --line-height-small:   calc(var(--line-height-base-unit) * 1.1);
  --line-height-medium:  calc(var(--line-height-base-unit) * 1.3);
  --line-height-large:   calc(var(--line-height-base-unit) * 1.5);
  --line-height-32:     calc(var(--line-height-base-unit) * 2);
  /* Font weights */
  --font-weight-lighter:  100;
  --font-weight-light:    200;
  --font-weight-normal:   400;
  --font-weight-bold:     700;
  --font-weight-bolder:   900;
/* Space
   ================================================================= */
  --space-base-unit: var(--base-unit);
  /* Margin, Padding - 8x8 grid */
  --space-4:  calc(var(--space-base-unit) * 1);
  --space-8:  calc(var(--space-base-unit) * 2);
  --space-12: calc(var(--space-base-unit) * 3);
  --space-16: calc(var(--space-base-unit) * 4);
  --space-24: calc(var(--space-base-unit) * 6);
  --space-32: calc(var(--space-base-unit) * 8);
  --space-40: calc(var(--space-base-unit) * 10);
  --space-44: calc(var(--space-base-unit) * 11);
  --space-48: calc(var(--space-base-unit) * 12);
  --space-64: calc(var(--space-base-unit) * 16);
  
/* Breakpoints
   ================================================================= */
  --breakpoint-small:     544px;
  --breakpoint-medium:    768px;
  --breakpoint-large:     1440px;
/* Color
   ================================================================= */
  --color-primary-lighter:#484848;
  --color-primary-light:  #303030;
  --color-primary:        #222222;
  --color-primary-dark:   #090909;
  --color-primary-darker: #080808;
  
  --color-secondary-ligher:   #FFFFFF;
  --color-secondary-light:    #FEFBF0;
  --color-secondary:          #FFFBEF;
  --color-secondary-dark:     #FFEEBC;
  --color-secondary-darker:   #FEE28F;

  --color-white: #FFFFFF;
  --color-gray-1: #FFFFFF;
  --color-gray-2: #EEEEEE;
  --color-gray-3: #D5D5D5;
  --color-gray-4: #BBBBBB;
  --color-gray-5: #A1A1A1;
  --color-gray-6: #888888;
  --color-gray-7: #6F6F6F;
  --color-gray-8: #555555;
  --color-gray-9: #3C3C3C;
  --color-gray-10: #222222;
  --color-gray-11: #201F20;
  --color-gray-12:#141414;


  --color-dark-0 : rgba(0, 0, 0, 0);
  --color-dark-1 : rgba(0, 0, 0, 0.04);
  --color-dark-2 : rgba(0, 0, 0, 0.08);
  --color-dark-3 : rgba(0, 0, 0, 0.16);
  --color-dark-4 : rgba(0, 0, 0, 0.24);
  --color-dark-5 : rgba(0, 0, 0, 0.32);
  --color-dark-6 : rgba(0, 0, 0, 0.48);
  --color-dark-7 : rgba(0, 0, 0, 0.64);
  --color-dark-8 : rgba(0, 0, 0, 0.8);
  --color-dark-9 : rgba(0, 0, 0, 0.9);
  --color-dark-10: rgba(0, 0, 0, 1);

  --color-light-0 : rgba(255, 255, 255, 0);
  --color-light-1 : rgba(255, 255, 255, 0.04);
  --color-light-2 : rgba(255, 255, 255, 0.08);
  --color-light-3 : rgba(255, 255, 255, 0.16);
  --color-light-4 : rgba(255, 255, 255, 0.24);
  --color-light-5 : rgba(255, 255, 255, 0.32);
  --color-light-6 : rgba(255, 255, 255, 0.48);
  --color-light-7 : rgba(255, 255, 255, 0.64);
  --color-light-8 : rgba(255, 255, 255, 0.8);

  --color-ui-0: #1E2033;
  --color-ui-1: #161724;
  --color-ui-2: #1C1D2C;
  --color-ui-3: #212335;
  --color-ui-4: #282A3E;
  --color-ui-5: #383B51;
  --color-ui-6: #40415A;
  --color-ui-7: #4D4F6E;
  --color-ui-8: #707297;

  --color-blue-1: #DCEEFA;
  --color-blue-2: #9ED8FF;
  --color-blue-3: #61B3FA;
  --color-blue-4: #49A3FD;
  --color-blue-5: #2196FD;
  --color-blue-6: #1285F8;
  --color-blue-7: #056EE6;
  --color-blue-8: #0B4CAD;
  --color-blue:   #2196FD;
  --color-blue-gamersclub: #356AFD; 

  --color-red-1: #FFE0E0;
  --color-red-2: #FABDBD;
  --color-red-3: #FC6973;
  --color-red-4: #FB4B56;
  --color-red-5: #F23641;
  --color-red-6: #DD2C2C;
  --color-red-7: #C31D1D;
  --color-red-8: #931A1A;
  --color-red:   #F23641;
/* Drop Shadow
   ================================================================= */
  --drop-shadow-default: var(--space-8) 0 var(--space-16) 0 var(--color-dark-4);

/* Border
   ================================================================= */
  /* Border color */
  --border-color-lighter: var(--gray-2);
  --border-color-light:   var(--gray-3);
  --border-color:         var(--gray-5);
  --border-color-dark:    var(--gray-7);
  --border-color-darker:  var(--gray-9);
  
  /* Border radius */
  --border-radius-small:  calc(var(--base-unit) * 1);
  --border-radius-medium: calc(var(--base-unit) * 2);
  --border-radius-large:  calc(var(--base-unit) * 4);
/* Transition
   ================================================================= */
  --transition-faster:    0.1s;
  --transition-fast:      0.2s;
  --transition-normal:    0.3s;
  --transition-slow:      0.4s;
  --transition-slower:    0.5s;
}
`
