import { html } from 'lit'

const t = {
  play_now: 'Play now',
  news: 'News',
  loading: 'Loading...',
  skip: 'Skip',
  next: 'Next',
  ok: 'Ok!',
  coach_step_1: html`Did you notice the new  <strong>Gamers Club's ecosystem</strong> navigation bar?`,
  coach_step_2: html`By clicking in this icon <ie-icon class="nav-menu__button" pointer="true" type="menu"></ie-icon> you can access <strong>all games and news</strong> from the GC universe <strong>in a single space!</strong>`,
  coach_step_3: html`Choose an item from the menu and <strong>enjoy the experience!</strong>`
}

export default t
