import { checkIfNewsIsAlreadyRead, findProduct, getDateElapsed, getNews, getWindowLanguageTag, markNewsAsRead, translate } from '@root/src/helpers'
import { languages, News } from '@src/types'
import { html, LitElement } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { styles } from './styles'

@customElement('drawer-menu')
export class DrawerMenu extends LitElement {
  static override styles = styles

  @property()
    selectedItem: string = 'CSGO'

  @property()
    defaultLanguage: languages = getWindowLanguageTag() as languages || languages.PT

  @property()
    withNews: boolean = true

  @state()
    _news: News[] = []
    //

  private _closeMenu () {
    this.dispatchEvent(new CustomEvent('globalnavclose', {
      bubbles: true,
      composed: true
    }))
  }

  private _hasUnreadNewsDispatcher (news: News[]) {
    this.dispatchEvent(new CustomEvent('hasunreadnews', {
      detail: {
        hasUnreadNews: this._thereIsUnreadNews(news)
      },
      bubbles: true,
      composed: true
    }))
  }

  private _setActiveItem (selectedItem: string) {
    // FIND ALL BUTTONS AND ADD ACTIVE ATRIBUTE
    this.shadowRoot?.querySelectorAll('ie-button').forEach((button) => {
      button.removeAttribute('selected')
      button.getAttribute('value')?.toLocaleUpperCase() === selectedItem.toLocaleUpperCase() && button.setAttribute('selected', '')
    })
  }

  private _markAsRead (news: News) {
    markNewsAsRead(news)
    this._news = [...this._news] // force update
    this._hasUnreadNewsDispatcher(this._news)
  }

  private _thereIsUnreadNews (news: News[]) {
    return news.filter((news) => !checkIfNewsIsAlreadyRead(news)).length > 0
  }

  private _canShowNews () {
    // Deactive api calls to integrated ecosystem
    return false
  }

  protected override async firstUpdated () {
    this._setActiveItem(this.selectedItem)
    if (this._canShowNews()) {
      await getNews(findProduct(this.selectedItem).toLowerCase(), this.defaultLanguage).then((news) => {
        this._news = news
        this._hasUnreadNewsDispatcher(news)
      })
    } else {
      this._news = []
    }
  }

  override render () {
    const { _news } = this
    const GA_PARAMETERS_BTN = `?utm_source=ie_globalnavbar&utm_medium=ie_btn_${this.selectedItem}`
    const GA_PARAMETERS_NEWS = `?utm_source=ie_globalnavbar&utm_medium=ie_news_${this.selectedItem}`
    return html`
      <div class="drawer-menu">
        <div class="drawer-menu__header" @click="${this._closeMenu}">
          <div class="drawer-menu__header_logo">
            <ie-icon type="gclogo"></ie-icon>
          </div>
          <div class="drawer-menu__header_close">
            <ie-icon type="close"></ie-icon>
          </div>
        </div>
        <div class="drawer-menu__body">
          <h2>${translate('play_now', this.defaultLanguage)}</h2>
          <section class="drawer-menu__body__module-play">

            <a href="https://gamersclub.com.br${GA_PARAMETERS_BTN}">
              <ie-button value="csgo">
                <ie-icon type="csgo"></ie-icon>
                <span>CS</span>
              </ie-button>
            </a>

            <div class="drawer-menu__body__module-play--itens">
            <a href="https://gamersclub.gg/valorant${GA_PARAMETERS_BTN}">
              <ie-button value="valorant">
                <ie-icon type="valorant"></ie-icon>
                <span>VALORANT</span>
              </ie-button>
            </a>

            <a href="https://gamersclub.gg/lol${GA_PARAMETERS_BTN}">
            <ie-button value="lol">
              <ie-icon type="lol"></ie-icon>
              <span>League of Legends</span>
            </ie-button>
            </a>
            
            <a href="https://gamersclub.gg/wildrift${GA_PARAMETERS_BTN}">
            <ie-button value="wildrift">
              <ie-icon type="wildrift"></ie-icon>
              <span>Wild Rift</span>
            </ie-button>
            </a>

            <a href="https://gamersclub.gg/fortnite${GA_PARAMETERS_BTN}">
            <ie-button value="fortnite">
              <ie-icon type="fortnite"></ie-icon>
              <span>Fortnite</span>
            </ie-button>
            </a>
          
            <!-- <a href="https://overwatch.gamersclub.gg/overwatch2${GA_PARAMETERS_BTN}">
              <ie-button value="overwatch2">
                <ie-icon type="overwatch2"></ie-icon>
                <span>Overwatch 2</span>
              </ie-button>
            </a> -->

            </div>
          </section>
          <h2>${translate('news', this.defaultLanguage)}</h2>
          <section class="drawer-menu__body__module-news">
            
            <a href="https://draft5.gg/${GA_PARAMETERS_BTN}">
            <ie-button value="d5" type="horizontal" >
              <ie-icon type="d5"></ie-icon>
              <span>DRAFT5</span>
            </ie-button>
            </a>

            <a href="https://valorantzone.gg/${GA_PARAMETERS_BTN}">
            <ie-button value="vzone" type="horizontal">
              <ie-icon type="vzone"></ie-icon>
              <span>VALORANT Zone</span>
            </ie-button>
            </a>

            <a href="https://tropafreefire.com.br/${GA_PARAMETERS_BTN}">
            <ie-button value="tff" type="horizontal">
              <ie-icon type="tff"></ie-icon>
              <span>Tropa Free Fire</span>
            </ie-button>
            </a>

          </section>
          ${this._canShowNews()
? html`
          <h2>Rolando nos esports</h2>       
          <section class="drawer-menu__body__module-feed">
          ${_news.length <= 0
            ? html`<div class="drawer-menu__loading">${translate('loading', this.defaultLanguage)}</div>`
            : _news.map((news) => html`
           
              <div 
                @mouseover=${() => this._markAsRead(news)}
                @click=${() => this._markAsRead(news)}
                class="drawer-menu__body__module-feed__item news-item">
                <a href="${news.link}${GA_PARAMETERS_NEWS}" target="_blank" rel="noopener noreferrer">
                  <figure>
                    <div class="news-item__data">
                      <h3>
                        ${news.source.toLocaleUpperCase()},
                        ${getDateElapsed(news.publicationDate)}
                        ${checkIfNewsIsAlreadyRead(news) ? '' : html`<ie-new-mark></ie-new-mark>`}
                      </h3>
                      <div class="news-title">${news.title}</div>
                    </div>
                    <img src="${news.image}" alt="${news.title}">
                  </figure>
                </a>
              </div>

            `)}    
          </section>`
 : ''}
          
        </div>
      </div>
    `
  }
}
