import { html, TemplateResult } from 'lit'
import en from '../locales/en/generic'
import es from '../locales/es/generic'
import pt from '../locales/pt/generic'
import { languages, translation_keys } from '../types'

export const translate = (key: translation_keys, language: languages): TemplateResult<1> => {
  switch (language) {
    case languages.EN:
      return html`${en[key]}`
    case languages.ES:
      return html`${es[key]}`
    case languages.PT:
      return html`${pt[key]}`
    default:
      return html`${en[key]}`
  }
}
