import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { designTokens } from '@ui-kit/design-tokens'
import { booleanPropConverter } from '@root/src/helpers/core'

export type FillOptions = 'transparent' | 'primary' | 'secondary' | 'default'

@customElement('ie-button')
export class Button extends LitElement {
  static override styles = [designTokens, css`
    
   .ie-button {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      width: 100%;
      padding: var(--space-8);
      gap: var(--space-8);

      background-color: transparent;
      color: var(--color-light-8);

      border: 1px solid var(--color-light-3);
      border-radius: var(--border-radius-small);

      font-family: var(--font-family-primary);
      font-size: var(--font-size-12);

      cursor: pointer;

      &.ie-button__notch {
        color: var(--color-white);
      }
    }

    .ie-button--filled{
      text-align: center;
      border: calc(var(--space-base-unit)*0.5) solid var(--color-light-2);
      background-clip: padding-box;
    }

    .ie-button--filled.ie-button--primary{
      background-color: var(--color-blue-gamersclub);
      border-color: var(--color-blue-gamersclub);
    }

    .ie-button--filled.ie-button--default{
      background-color: var(--color-light-2);
    }

    :host(:hover) .ie-button--filled.ie-button--primary{
      background-color: var(--color-blue-8);
      border: calc(var(--space-base-unit)*0.5) solid var(--color-blue-gamersclub);
    }

    :host(:hover) .ie-button--filled.ie-button--default{
      border: calc(var(--space-base-unit)*0.5) solid var(--color-light-2);
    }

    :host(:hover) .ie-button.ie-button--filled .ie-button__notch{
      display: none;
    }


    .ie-button--horizontal{
      flex-direction: row;
      justify-content: flex-start; 

      font-size: var(--font-size-14);
    }

    /**STATE ATTRIBUTES**/
    .ie-button__notch{
      background-color: var(--color-light-3);
      width: var(--space-24);
      height: var(--space-4);
      border-radius: var(--border-radius-large);
      position: absolute;
      bottom: 0;
      display: none;
    }
    .ie-button--horizontal .ie-button__notch{
      width: var(--space-4);
      height: var(--space-24);
      bottom: calc(50% - var(--space-12));
      left: 0;
    }

    :host([disabled]){
      opacity: 0.2;
    }
    :host([selected]) .ie-button__notch{
      display: block;
      background-color: var(--color-blue);
    }
    :host(:hover) .ie-button__notch{
      display: block; 
    }
    :host(:hover) .ie-button{
      background-color: var(--color-light-1);
      border: 1px solid var(--color-light-2);
    }
  `]

  @property(booleanPropConverter)
    selected?: boolean = false

  @property(booleanPropConverter)
    disabled?: boolean = false

  @property()
    type?: string = undefined

  @property()
    fill?: FillOptions = 'transparent'

  @property()
    clickEvent?: string = undefined

  private readonly _eventDispatcher = (event: string) => {
    this.dispatchEvent(new CustomEvent(event, {
      detail: {
        payload: 'close'
      },
      bubbles: true,
      composed: true
    }))
  }

  private readonly _onClickButton = () => {
    if (this.clickEvent) {
      this._eventDispatcher(this.clickEvent)
    }
  }

  override render () {
    const fillClassName = this.fill && this.fill !== 'transparent' ? `ie-button--filled ie-button--${this.fill}` : ''
    const className = `ie-button ${this.type && this.type !== '' ? `ie-button--${this.type}` : ''}`
    return html`
       <button class="${className} ${fillClassName}" @click=${this._onClickButton}>
          <slot></slot>
          <span class="ie-button__notch"></span>
       </button>
     `
  }
}
