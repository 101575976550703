import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { designTokens } from '@ui-kit/design-tokens'
import { GCBrand, GCProduct } from '@root/src/types'

import { d5, fortnite, tff, vzone, valorant, lol, wildrift, bedlam, csgo, close, menu, gclogo, arrowLeft, thumbsUp, overwatch2 } from '../../assets'

export const AvailableIcons = {
  D5: d5,
  VZONE: vzone,
  TFF: tff,
  VALORANT: valorant,
  LOL: lol,
  WILDRIFT: wildrift,
  BEDLAM: bedlam,
  CSGO: csgo,
  CLOSE: close,
  MENU: menu,
  GCLOGO: gclogo,
  FORTNITE: fortnite,
  ARROWLEFT: arrowLeft,
  THUMBSUP: thumbsUp,
  OVERWATCH2: overwatch2
}

export interface IconProps {
  type: GCProduct | GCBrand
  size?: 'small' | 'medium' | 'large'
}

@customElement('ie-icon')
export class Icon extends LitElement {
  static override styles = [designTokens, css`
    :host {
      display: inherit;
    }
    .icon{
      display: inline-flex;
    }
   .pointer{
      cursor: pointer;
   }
   object{
      pointer-events: none;
   }
  `]

  @property({
    converter: (value: string | null) => {
      if (!value) return
      return value.toUpperCase() as GCProduct | GCBrand
    }
  })
    type: IconProps['type'] = 'CSGO'

  @property({
    converter: (value: string | null) => {
      if (!value) return false
      return true
    }
  })
    pointer: boolean = false

  override render () {
    const icon = AvailableIcons[this.type as keyof typeof AvailableIcons]
    return html`
      <div class="icon ${this.pointer ? 'pointer' : ''}">
        <object data="${icon}" type="image/svg+xml">
          <img src="${icon}" alt="alternative icon for ${this.type}" />  
        </object>
      </div>
     `
  }
}
