export enum languages {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}
export type translation_keys = 'play_now' | 'news' | 'loading' | 'skip' | 'next' | 'ok' | 'coach_step_1' | 'coach_step_2' | 'coach_step_3'

export enum GCMediaBrandsAbbr {
  D5 = 'd5',
  VZONE = 'vzone',
  TFF = 'tff',
}

export enum GCMediaBrands {
  D5 = 'DRAFT5',
  VZONE = 'VALORANT Zone',
  TFF = 'Tropa Free Fire'
}

export enum GCMediaRelatedProducts {
  D5 = 'CSGO',
  VZONE = 'VALORANT',
  TFF = 'FREEFIRE'
}
export enum ProductsRelatedToGCMedia {
  CSGO = 'D5',
  VALORANT = 'VZONE',
  FREEFIRE = 'TFF'
}

export enum GCBrandsAbbr {
  BEDLAM = 'bedlam',
  MULTIGAMING = 'multigaming',
  V1 = 'v1' // v1
}

export enum GCBrands {
  BEDLAM = 'Bedlam',
  MULTIGAMING = 'Multigaming',
  V1 = 'CS' // v1
}

export enum GCProductsAbbr {
  CSGO = 'csgo',
  VALORANT = 'valorant',
  FREEFIRE = 'freefire',
  LOL = 'lol',
  FORTNITE = 'fortnite',
  WILDRIFT = 'wildrift',
  OVERWATCH2 = 'overwatch2'
}

export enum GCProducts {
  CSGO = 'CS',
  VALORANT = 'VALORANT',
  FREEFIRE = 'FreeFire',
  LOL = 'League of Legends',
  FORTNITE = 'Fortnite',
  WILDRIFT = 'Wild Rift',
  OVERWATCH2 = 'Overwatch 2'
}

export interface News{
  title: string
  publicationDate: string
  link: string
  image: string
  source: string
}

export type GCProduct = keyof typeof GCProducts

export type GCBrand = keyof typeof GCBrands | keyof typeof GCMediaBrandsAbbr // GAMES SERAO PRODUTOS?
