import { LitElement, css, html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { designTokens } from '@ui-kit/design-tokens'
import { GlobalNavBarProps } from './types'
import { getWindowLanguageTag, isGCMediaBrand, isMobile } from '@root/src/helpers'
import { languages } from '@root/src/types'
import { booleanPropConverter } from '@root/src/helpers/core'
@customElement('global-navbar')
export class GlobalNavBar extends LitElement {
  @property()
    product: GlobalNavBarProps['product'] = 'CSGO'

  @property()
    brand: GlobalNavBarProps['brand'] = 'V1'

  @property()
    fixed: GlobalNavBarProps['fixed'] = false

  @property()
    withNews: GlobalNavBarProps['withNews'] = true

  @property()
    withCoachMarks: GlobalNavBarProps['withCoachMarks'] = false

  @property()
    defaultLanguage: GlobalNavBarProps['defaultLanguage'] = getWindowLanguageTag() as languages || languages.PT

  @property(booleanPropConverter)
    isSimpleBar: GlobalNavBarProps['isSimpleBar'] = false

  @state()
  private _isMenuOpened = false

  @state()
  private _hasUnreadNews = false

  private _toggleMenu () {
    this._isMenuOpened = !this._isMenuOpened
  }

  private _openMenu () {
    this._isMenuOpened = true
  }

  private _closeMenu () {
    this._isMenuOpened = false
  }

  /**
   * @description This method is used to close the menu when the user clicks outside of it
   */
  private _closeMenuEvent (e: CustomEvent) {
    e.stopPropagation()
    this._closeMenu()
  }

  /**
   * @description This method is used to open the menu when the user is in step 3 of the coach marks
   */
  private _openMenuEvent (e: CustomEvent) {
    e.stopPropagation()
    this._openMenu()
  }

  /**
   * @description This method is used to get the status of unread news mark
   */
  private _getUnreadNewsStatus (e: CustomEvent) {
    e.stopPropagation()
    this._hasUnreadNews = e.detail?.hasUnreadNews ?? false
  }

  static override styles = [designTokens, css`
    
    :host {
      font-family: var(--font-family-gamersclub);
    }
    
    nav{
      z-index: 99999;
      background-color: var(--color-ui-0);
      height: var(--space-64);
      display: flex;
      position:relative;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    
    nav.fixed{
      position: fixed;
    }

    .nav-wrapper{
      display: flex;
      align-items: center;
      align-self: stretch;
      width: 100%;
      color: var(--color-gray-1);
      z-index: 2;
    }
    .nav-unit{
      display: flex;
      height: 100%;
      align-items: center;
      flex:1;
      min-width: var(--space-64);
    }
    .nav-menu{
      display: flex;
      position: relative;
      align-items: center;
      flex-wrap: wrap;
      gap: var(--space-8);
      cursor: pointer;
      line-height: var(--space-24);
    }
    .nav-menu > :first-child{
      padding-left: var(--space-24);
    }
    
    .nav-menu__button{
      width: var(--space-24);
      height: var(--space-24);
    }

    .nav-menu__text > ie-Icon{
      height: var(--space-24);
    }

    .nav-menu > ie-new-mark{
      position: absolute;
      left: var(--space-40);
      bottom: var(--space-12);
    }
    
    .nav-brand {
      flex: 10;

    }

    .nav-brand__logo{
      font-family: var(--font-family-primary);
      display: inline-flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: var(--space-16);
      
      font-weight: 600;
      font-size: var(--font-size-20);
    }

    .nav-brand__gc-logo{
      display: inline-flex; 
      height: 100%;
      align-items: center;
      flex-wrap: wrap;
      gap: var(--space-4);
    }

    /*********** ONLY GCMEDIA  ***********/
    .nav-wrapper:not(.nav-wrapper__gc-media) .nav-unit:not(:first-child){
      display: none;
    }
    /************************************/

    /*********** MENU BASICS  ***********/
    .global-nav__shadow{
      background-color: var(--color-dark-6);
      display: none;
      opacity: 0;
      position: fixed;
      width: 100%;
      height: 100%;
      transition: opacity 1s;
      cursor: pointer;   
    }
    .global-nav--opened .global-nav__shadow{
      top: 0;
      left: 0;
      display: block;
      opacity: 1;
    }

    .nav-drawer-menu-wrapper{
      width: calc(var(--space-64)*5); 
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-gray-11);
      border-right: 1px solid var(--color-light-1);
      transition: transform var(--transition-normal) cubic-bezier(1, 0, 0, 1); 
      transform: translate( calc(var(--space-64)*5*-1));
      z-index: 3;
    }
    nav.fixed .nav-drawer-menu-wrapper{
      height: 100vh;
      overflow-y: auto;
    }
    nav.fixed .nav-drawer-menu-wrapper::-webkit-scrollbar{
      width: calc(var(--space-4)/2);
    }
    /* Track */
    nav.fixed .nav-drawer-menu-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 var(--space-4) var(--color-light-2);
      border-radius: var(--border-radius-large);
    }

    /* Handle */
    nav.fixed .nav-drawer-menu-wrapper::-webkit-scrollbar-thumb {
      background: var(--color-blue-gamersclub);
      border-radius: var(--border-radius-large);
    }
    
    .global-nav--opened .nav-drawer-menu-wrapper{
      transform: translate(0);

      box-shadow: var(--drop-shadow-default);
    }
    /************************************/
    
    


  `]

  override render () {
    // BRAND REAL NAME
    const isGCMediaClass = isGCMediaBrand(this.brand) ? 'nav-wrapper__gc-media' : ''
    const isMobileClass = isMobile() ? 'nav-wrapper__mobile' : ''
    const isFixedClass = this.fixed ? 'fixed' : ''
    const isMenuOpenedClass = this._isMenuOpened ? 'global-nav--opened' : ''
    const globalNavClass = `global-nav ${isMenuOpenedClass} ${isFixedClass}`
    return html`
      <nav
        @hasunreadnews=${this._getUnreadNewsStatus}
        class="${globalNavClass}" >
        ${!this.isSimpleBar
? html`
        ${this.withCoachMarks
? html`
          <coach-marks @globalnavopen=${this._openMenuEvent} defaultLanguage=${this.defaultLanguage}></coach-marks>
        `
 : ''}
        
        <div class="global-nav__shadow" @click="${this._closeMenu}"></div>
        <div class="nav-drawer-menu-wrapper">
          <drawer-menu selectedItem=${this.brand || this.product} @globalnavclose=${this._closeMenuEvent} defaultLanguage=${this.defaultLanguage} ></drawer-menu>
        </div>
        `
: ''}
        
        <div class="nav-wrapper ${isMobileClass} ${isGCMediaClass}">
          <div 
            class="nav-menu nav-unit" 
            @click="${this._toggleMenu}"
            > 
            ${!this.isSimpleBar
? html`
            <ie-icon class="nav-menu__button" pointer="true" type="menu"></ie-icon>
            `
: ''}
            
            ${this._hasUnreadNews ? html`<ie-new-mark></ie-new-mark>` : ''}
            <div class="nav-menu__text">
              <ie-icon type="gclogo"></ie-icon>
            </div>
          </div>

        </div>
        
      </nav>
      
     `
  }
}
